/**
 * Cart
 * -------------------------
 */

.ce-cart {
  &__left {
    border-bottom: rem-calc(2) solid $divider;
    padding: 0 0 rem-calc(20) 0;
  }

  &__right {
    padding: rem-calc(20) 0 0 0;
  }

  &__intro {
    margin-bottom: rem-calc(40);

    .headline {
      margin-bottom: rem-calc(10);
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;

    &__left {
      border-bottom: none;
      border-right: rem-calc(2) solid $divider;
      padding: 0 rem-calc(60) 0 0;
      width: 66%;
    }

    &__right {
      padding: 0 0 0 rem-calc(60);
      width: 34%;
    }
  }
}

.cart {
  @include copy-text();

  &__item {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: rem-calc(40);
    }

    &-image img {
      width: rem-calc(200);
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    &-title {
      font-weight: bold;
    }

    &-description {
      display: flex;
      gap: rem-calc(20);
      align-items: center;
      width: 100%;

      .cart__item-image {
        width: 40%;
        max-width: rem-calc(200);
        flex-shrink: 0;
      }

      .cart__item-title {
        margin-bottom: rem-calc(10);
      }
    }

    &-price {
      display: flex;
      flex-wrap: wrap;
      gap: rem-calc(20);
      text-align: right;
    }

    @include media-breakpoint-down(sm) {
      &-description {
        margin-bottom: rem-calc(20);

        .cart__item-image {
          width: 25%;
        }
      }

      &-quantity {
        width: 40%;
        margin-bottom: rem-calc(10);
      }

      &-price {
        justify-content: flex-end;
        width: 60%;
        margin-bottom: rem-calc(10);
        padding-left: rem-calc(10);
      }

      &-remove {
        width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      align-items: center;
      justify-content: space-between;

      &-description {
        gap: rem-calc(40);
        width: 60%;

        .cart__item-text {
          margin-bottom: rem-calc(10);
        }
      }

      &-price {
        flex-direction: column;
      }
    }

    @include media-breakpoint-up(lg) {
      &-description {
        .cart__item-image {
          width: 50%;
        }
      }
    }
  }

  &__actions {
    text-align: right;
  }
}

.mini-cart {
  margin-bottom: rem-calc(40);

  &__item {
    @include copy-text();
    display: flex;
    padding: rem-calc(10);
    background: $light-gray;

    &:not(:last-child) {
      margin-bottom: rem-calc(10);
    }

    &-description {
      flex: 1 1 auto;
    }

    &-title {
      font-weight: bold;
      margin-bottom: rem-calc(10);
    }

    &-quantity,
    &-price {
      font-weight: bold;
      white-space: nowrap;
    }
  }

  @include media-breakpoint-down(xs) {
    &__item {
      flex-wrap: wrap;
      gap: rem-calc(7);
      justify-content: space-between;

      &-description {
        order: 1;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__item {
      gap: rem-calc(20);

      &-quantity {
        order: -1;
        //width: rem-calc(60);
      }

      /*&-price {
          width: rem-calc(100);
      }*/
    }
  }
}

.cart-summary {
  width: 100%;
  margin-bottom: rem-calc(40);

  tr:not(:last-child) td {
    padding-bottom: rem-calc(30);
  }

  &__value {
    text-align: right;
    font-weight: bold;
  }

  &__label,
  &__value {
    &--total {
      font-weight: bold;
      font-size: rem-calc(25);
      line-height: rem-calc(35);
    }
  }
}

.order-confirmation {
  .headline {
    margin-bottom: rem-calc(20);
  }

  table {
    @include copy-text();

    th {
      font-weight: normal;
      padding: 0 rem-calc(20) rem-calc(20) 0;
    }

    td {
      font-weight: bold;
      padding: 0 0 rem-calc(20) 0;
    }
  }

  @include media-breakpoint-up(md) {
    table th {
      min-width: rem-calc(190);
    }
  }
}
