/**
 * Image
 * -------------------------
 */

.ce-image {
  text-align: center;

  &--center {
    .figure figcaption {
      text-align: center;
    }
  }

  @include media-breakpoint-up(md) {
    &--medium {
      @include make-row();

      .ce-image__inner {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--medium {
      .ce-image__inner {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
  }
}
