/**
 * Facts-Box
 * -------------------------
 */

.ce-facts-box-teaser {
  @include animate(background-color box-shadow);
  display: flex;
  flex: 1 0 auto;

  &__content {
    display: flex;
    gap: rem-calc(20);
    padding: rem-calc(20);
    width: 100%;
  }

  &__overline {
    @extend %tiny;
    margin-bottom: rem-calc(10);
  }

  .headline:not(:last-child) {
    margin-bottom: rem-calc(10);
  }

  &__icon {
    img {
      height: auto;
      width: rem-calc(60);
    }
  }

  &__text {
    @extend %copy;
    max-width: rem-calc(785);

    &:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  &__link {
    margin-top: rem-calc(10);
  }

  &:not(div) {
    @include hover-focus() {
      background: $white-hover;
    }
  }

  &--fixed-height {
    flex-grow: 0;
  }

  &--middle {
    .ce-facts-box-teaser__text-wrapper,
    .ce-facts-box-teaser__content {
      align-self: center;
    }
  }

  &--blue {
    background: $blue;
    color: $white;

    &:not(div) {
      @include hover-focus() {
        background: $blue-hover;
        color: $white;
      }
    }
  }

  &--gray {
    background: $light-gray;

    &:not(div) {
      @include hover-focus() {
        background: $light-gray-hover;
      }
    }
  }

  &--light {
    background: $light-gray;
    color: $primary;

    &:not(div) {
      @include hover-focus() {
        background: $light-gray-hover;
        color: $primary;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__content {
      padding-left: rem-calc(30);
      padding-right: rem-calc(30);
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      gap: rem-calc(26);
    }

    &__link {
      margin-top: rem-calc(30);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      flex-wrap: wrap;
      padding: rem-calc(80) rem-calc(30);
      text-align: center;
    }

    &__icon {
      margin-top: rem-calc(-20);
    }

    &__icon,
    &__text-wrapper {
      width: 100%;
    }

    &__text {
      max-width: rem-calc(400);
      margin-left: auto;
      margin-right: auto;
    }

    &__text-wrapper {
      align-self: initial;
    }
  }
}
