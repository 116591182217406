/**
 * Pagination
 * --------------------
 */

.pagination {
  @include list-unstyled();
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc(6);
  justify-content: center;
  margin-top: rem-calc(80);

  &__page,
  &__prev,
  &__next {
    a {
      display: block;
    }
  }

  &__page {
    a {
      border: rem-calc(2) solid $primary;
      line-height: rem-calc(32);
      font-size: rem-calc(17);
      min-width: rem-calc(36);
      padding: rem-calc(2);
      text-align: center;

      &:hover,
      &:focus {
        background: $primary-hover;
        color: $white;
      }
    }

    &--current a {
      background: $primary-hover;
      color: $white;
    }
  }

  &__prev,
  &__next {
    a {
      @include rem-size(40);
      background: url('#{$image-base-uri}icon-arrow-big-right-dark.svg') no-repeat center;
      background-size: contain;
    }
  }

  &__prev a {
    transform: rotate(180deg);
  }
}
