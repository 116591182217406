/**
 * Breadcrumb
 * -------------------------
 */

.breadcrumb {
  padding: rem-calc(10) rem-calc(15) rem-calc(15);

  &__item {
    @extend %tiny;
    color: $dark-gray;
    display: inline;

    a {
      @include animate(opacity);
      color: inherit;
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &:not(:last-child):after {
      @include rem-size(10, 14);
      background: url('#{$image-base-uri}icon-chevron.svg') no-repeat;
      content: '';
      display: inline-block;
      position: relative;
      top: rem-calc(1);
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: rem-calc(20);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }
}
