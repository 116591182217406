/**
 * Vendor
 * --------------------
 */

// slick carousel
.slick-slider {
  .slick-slide > div > div {
    display: block !important;
  }

  .slick-arrow {
    @include animate(color opacity);
    @include rem-size(40);
    background: none;
    border: none;
    bottom: rem-calc(10);
    color: map-get($font-colors, 'light');
    display: block;
    outline: none;
    padding: 0;
    position: absolute;
    z-index: 1;

    @if $theme == 'nextel' {
      top: calc(50% - #{rem-calc(20)});
    } @else {
      bottom: rem-calc(10);
    }

    svg {
      fill: currentColor;
    }

    @each $name, $color in $font-colors {
      &[data-color="#{$name}"] {
        color: $color;
      }
    }

    &.slick-disabled {
      pointer-events: none;
      opacity: 0;
    }

    &.slick-next {
      @if $theme == 'nextel' {
        right: 0;
      } @else {
        right: rem-calc(10);
      }
    }

    &.slick-prev {
      transform: rotate(180deg);

      @if $theme == 'nextel' {
        left: 0;
      } @else {
        left: rem-calc(10);
      }
    }
  }

  .slick-dots {
    @extend .unstyled-list;
    @include clearfix();
    bottom: rem-calc(25);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    li {
      float: left;

      &:not(:last-child) {
        margin-right: rem-calc(15);
      }

      button {
        @include animate(background-color border-color);
        @include rem-size(10);
        background: none;
        border: rem-calc(2) solid $white;
        border-radius: 50%;
        display: block;
        outline: none;
        overflow: hidden;
        padding: 0;
        text-indent: 100%;
        white-space: nowrap;
      }

      &.slick-active {
        button {
          background: $white;
        }
      }
    }

    @each $name, $color in $font-colors {
      &[data-color="#{$name}"] {
        li {
          button {
            border-color: $color;
          }

          &.slick-active {
            button {
              background: $color;
            }
          }
        }
      }
    }
  }
}
