/**
 * Fonts
 * -------------------------
 */

@font-face {
  font-family: 'Lab Grotesque';
  src: url('#{$font-base-uri}labgrotesque-regular.woff2') format('woff2'),
  url('#{$font-base-uri}labgrotesque-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lab Grotesque';
  src: url('#{$font-base-uri}labgrotesque-bold.woff2') format('woff2'),
  url('#{$font-base-uri}labgrotesque-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Absolut Pro Reduced';
  src: url('#{$font-base-uri}absolut-pro-bold-reduced.woff2') format('woff2'),
  url('#{$font-base-uri}absolut-pro-bold-reduced.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
