/**
 * Map
 * -------------------------
 */

.map {
  position: relative;

  &__map {
    height: 100%;
  }
}

.map-overlay {
  @include stretch-to-parent();
  align-items: center;
  display: flex;
  justify-content: center;

  &__background {
    @include stretch-to-parent();
  }

  &__content {
    background: $white;
    box-shadow: $box-shadow-overlay;
    padding: rem-calc(40) rem-calc(60) rem-calc(40) rem-calc(30);
    position: relative;
    width: rem-calc(400);
  }

  &__close {
    @extend .close;
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(10);
  }

  &__title {
    @include tiny-text();
    font-weight: bold;
  }

  &__body {
    p, address {
      @include tiny-text();
      margin: 0;
    }

    address:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    p:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }
}
