/**
 * Colors
 * -------------------------
 */

.ce-colors {
  &__nav {
    background: $light-gray;
  }

  &__nav-item {
    background: $light-gray;
    color: $primary;
    display: block;
    font-size: rem-calc(17);
    font-weight: bold;
    line-height: rem-calc(26);
    min-width: rem-calc(160);
    padding: rem-calc(7) rem-calc(20);
    text-align: center;

    @at-root .slick-current &,
    &:hover {
      background: $primary;
      color: $white !important;
    }

    @media (pointer: none) {
      &:hover {
        background: $light-gray;
        color: $primary !important;
      }
    }
  }

  &__slide {
    padding: rem-calc(50) $grid-gutter-width/2 0;
    width: 100%;

    .headline {
      margin: 0 0 rem-calc(40);
      text-align: center;
    }
  }

  &__colors {
    @include make-row();
  }

  &__color {
    @include make-col-ready();
    @include make-col(6);
    margin-bottom: $grid-gutter-width;

    > a {
      @include animate(box-shadow);
      display: block;

      &:hover,
      &:focus {
        box-shadow: $box-shadow-dark-hover;
      }
    }

    &-wrapper {
      border: rem-calc(2) solid $divider;
      height: 100%;
    }

    &-preview {
      background-position: center;
      background-size: cover;
      padding-top: 100%;
    }

    &-description {
      padding: rem-calc(12) rem-calc(10);
    }

    &-code {
      @extend %tiny;
      color: $primary;
    }

    &-title {
      @extend %copy;
      min-height: rem-calc(52);
    }
  }

  @include media-breakpoint-up(sm) {
    &__color {
      @include make-col(4);
    }
  }

  @include media-breakpoint-up(md) {
    &__color {
      @include make-col(3);
    }
  }

  @include media-breakpoint-up(lg) {
    &__nav-item {
      padding-bottom: rem-calc(12);
      padding-top: rem-calc(12);
    }

    &__slide {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }

    &__color {
      @include make-col(3, 24);
    }
  }
}
