/**
 * Video
 * -------------------------
 */

.ce-video {
  &--center {
    .figure figcaption {
      text-align: center;
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__inner {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  @include media-breakpoint-up(lg) {
    &__inner {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}
