/**
 * Mixins and functions
 * --------------------
 */

@function rem-calc($value) {
  @return $value / 16 * 1rem;
}

@mixin rem-size($width, $height: $width) {
  width: rem-calc($width);
  height: rem-calc($height);
}

@mixin icon($path, $width: 0, $height: 0, $position: 'left', $color: 'transparent') {
  background: #{$color} url('#{$image-base-uri}#{$path}') no-repeat #{$position};
  @if $width > 0 and $height > 0 {
    background-size: rem-calc($width) rem-calc($height);
  } @else {
    background-size: contain;
  }
}

@mixin stretch-to-parent($position: absolute) {
  bottom: 0;
  left: 0;
  position: $position;
  right: 0;
  top: 0;
}

@mixin wallpaper-element() {
  @include stretch-to-parent();
  background-position: center;
  background-size: cover;
}

@mixin font-family($font-family) {
  @if $font-family != $font-family-base {
    font-family: $font-family;
  }
}

@mixin font-color-variants($default: null, $isLink: false) {
  @if $default and not map-has-key($font-colors, $default) {
    @error "`#{$default}` is not a valid color-variant (see $font-colors).";
  }

  @if $default and map-has-key($font-colors, $default) {
    color: map-get($font-colors, $default);

    a {
      color: map-get($font-colors, $default);
    }

    @if $isLink {
      &:hover,
      &:focus {
        color: map-get($font-colors, $default);
      }
    }
  }

  @each $name, $color in $font-colors {
    @if $name != $default {
      &--#{$name}-font {
        color: $color;

        a {
          color: $color;
        }

        @if $isLink {
          &:hover,
          &:focus {
            color: $color;
          }
        }
      }
    }
  }
}

@mixin background-color-variants($default: null, $opacity: 1) {
  @if $default and not map-has-key($background-colors, $default) {
    @error "`#{$default}` is not a valid color-variant (see $background-colors).";
  }

  @if $default and map-has-key($background-colors, $default) {
    background-color: rgba(map-get($background-colors, $default), $opacity);
  }

  @each $name, $color in $background-colors {
    @if $name != $default {
      &--#{$name}-bg {
        background-color: rgba($color, $opacity);
      }
    }
  }
}

@mixin animate($properties) {
  $value: ();
  @each $property in $properties {
    $value: append($value, $property 300ms, comma);
  }
  transition: $value
}

@mixin headline($size) {
  @include font-family($font-family-headlines);
  font-weight: bold;
  line-height: 1.2;

  @if $size == "sm" {
    font-size: rem-calc(20);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(25);
    }
  } @else if $size == "md" {
    font-size: rem-calc(25);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(30);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(40);
    }
  } @else if $size == "lg" {
    font-size: rem-calc(25);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(50);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(60);
    }
  } @else if $size == "xl" {
    font-size: rem-calc(35);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(50);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(70);
    }
  }
}

@mixin copy-text() {
  font-size: rem-calc(17);
  line-height: rem-calc(26);
}

@mixin tiny-text() {
  font-size: rem-calc(14);
  line-height: rem-calc(17);
}
