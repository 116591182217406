/**
 * Menus
 * -------------------------
 */

.menu-container-toggle {
  @include media-breakpoint-down(md) {
    background: url('#{$image-base-uri}icon-burger.svg') no-repeat center;
    bottom: 0;
    cursor: pointer;
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    width: rem-calc(50);
    z-index: 1;

    @include hover() {
      background-image: url('#{$image-base-uri}icon-burger-hover.svg');
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.menu {
  a {
    color: $dark-gray;

    @include hover {
      color: $link-hover-color;
    }
  }

  &--company,
  &--legal {
    .menu__item {
      &:not(:last-child) {
        margin-bottom: rem-calc(10);
      }

      a,
      span {
        color: $medium-gray;
        font-size: rem-calc(14);
        line-height: rem-calc(17);
      }

      a {
        @include animate(opacity);
        opacity: 0.5;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }

  &__item-content {
    display: none;
  }

  @include media-breakpoint-down(md) {
    &--main,
    &--meta {
      display: none;
    }

    &__item-cart-counter {
      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--main {
      font-size: rem-calc(17);
      letter-spacing: rem-calc(1);
      line-height: rem-calc(20);
      position: absolute;
      right: rem-calc(40);
      top: rem-calc(30);

      @at-root .search + & {
        right: rem-calc(106);
      }

      .menu__items {
        @include clearfix();
      }

      .menu__item {
        float: left;

        &:not(:last-child) {
          margin-right: rem-calc(40);
        }

        a {
          line-height: rem-calc(26);
        }

        &--open a {
          border-bottom: rem-calc(2) solid $primary;
          color: $primary;
        }

        .menu__items {
          display: none;
        }

        &-cart-counter {
          display: block;
          position: absolute;
          min-width: rem-calc(20);
          padding: 0 rem-calc(4);
          left: calc(100% - #{rem-calc(5)});
          top: rem-calc(-10);
          text-align: center;
          background: $primary;
          color: $white;
          border-radius: rem-calc(10);
          font-size: rem-calc(12);
          line-height: rem-calc(20);
        }
      }
    }

    &--meta {
      font-size: rem-calc(14);
      line-height: rem-calc(40);
      position: absolute;
      right: rem-calc(40);
      top: rem-calc(-40);

      ul {
        @include clearfix();
      }

      li {
        float: left;

        &:not(:last-child) {
          margin-right: rem-calc(30);
        }
      }

      a {
        color: $dark-gray;

        @include hover {
          color: $link-hover-color;
        }
      }

      @at-root .language-switcher + & {
        right: rem-calc(110);
      }
    }
  }
}

.menu-overlay {
  z-index: 11;

  &__items {
    background: $light-gray;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    a {
      border-bottom: rem-calc(1) solid $divider;
      color: $dark-gray;
      display: block;

      &:hover,
      &:focus {
        color: $link-hover-color;
      }
    }

    &--has-children > a {
      background: url('#{$image-base-uri}menu-arrow-dark.svg') no-repeat right rem-calc(10) center;
    }

    .menu-overlay__items {
      display: none;
    }

    &--open {
      background: $light-gray-hover;
    }

    &--important {
      font-weight: bold;
    }
  }

  &--mobile {
    @include stretch-to-parent(fixed);
    background: $white no-repeat rem-calc(15) rem-calc(12);
    padding: rem-calc(54) rem-calc(15) rem-calc(15);

    @if $theme == 'mankiewicz' {
      background-image: url('#{$image-base-uri}logo-mankiewicz.svg');
      background-size: rem-calc(186) rem-calc(31);
    } @else if $theme == 'alexseal' {
      background-image: url('#{$image-base-uri}logo-alexseal.svg');
      background-size: rem-calc(136) rem-calc(31);
    } @else if $theme == 'bladerep' {
      background-image: url('#{$image-base-uri}logo-bladerep.svg');
      background-size: rem-calc(173) rem-calc(31);
    } @else if $theme == 'nextel' {
      background-image: url('#{$image-base-uri}logo-nextel.svg');
      background-size: rem-calc(138) rem-calc(31);
    } @else if $theme == 'ruedt' {
      background-image: url('#{$image-base-uri}logo-ruedt.svg');
      background-size: rem-calc(89) rem-calc(31);
    } @else if $theme == 'schuppenpanzer' {
      background-image: url('#{$image-base-uri}logo-schuppenpanzer.svg');
      background-size: rem-calc(247) rem-calc(21);
      background-position-y: rem-calc(17);
    }

    .menu-overlay__back-link {
      @include animate(opacity);
      background: $white url('#{$image-base-uri}icon-arrow-left-dark.svg') no-repeat left center;
      color: $primary;
      display: block;
      font-size: rem-calc(17);
      font-weight: bold;
      min-width: rem-calc(186);
      opacity: 0;
      padding-left: rem-calc(30);
      position: absolute;
      line-height: rem-calc(50);
      top: 0;
      pointer-events: none;

      &--visible {
        opacity: 1;
        pointer-events: initial;
      }
    }

    .menu-overlay__close-button {
      @include rem-size(50);
      background: url('#{$image-base-uri}icon-close.svg') no-repeat center;
      position: absolute;
      right: rem-calc(15);
      top: 0;

      &:hover,
      &:focus {
        background-image: url('#{$image-base-uri}icon-close-hover.svg');
      }
    }

    .menu-overlay__scroll-area {
      background: $white;
      max-height: calc(100vh - #{rem-calc(69)});
      overflow: auto;
    }

    .menu-overlay__search {
      display: block;
      margin-bottom: rem-calc(30);
    }

    .menu-overlay__items {
      &:not(:last-child) {
        margin-bottom: rem-calc(30);
      }

      &--meta {
        .menu-overlay__item a {
          border: none;
          font-size: rem-calc(14);
          line-height: rem-calc(18);
          padding: rem-calc(11) rem-calc(15);
        }
      }

      &--language {
        .menu-overlay__item a {
          background-color: $primary;
          color: $white;
          font-size: rem-calc(14);
          line-height: rem-calc(18);
          padding: rem-calc(11) rem-calc(15);
        }
      }
    }

    .menu-overlay__item {
      a {
        line-height: rem-calc(60);
        padding: 0 rem-calc(15);

        .flag {
          height: rem-calc(60);
          float: left;
          margin-right: rem-calc(10);
        }
      }

      &--has-children > a {
        background-position: right rem-calc(20) center;
      }
    }
  }

  &--desktop {
    height: calc(100% - #{rem-calc(120)});
    padding: 0 rem-calc(20);
    position: absolute;
    left: 0;
    top: rem-calc(60);
    width: 100%;

    .menu-overlay__background {
      @include stretch-to-parent();
      background: transparentize($black, 0.75);
    }

    .menu-overlay__container {
      background: $body-bg;
      margin: 0 auto;
      max-width: rem-calc(1600);
      position: relative;
    }

    .menu-overlay__columns {
      display: flex;
    }

    .menu-overlay__column {
      flex: 1 0 auto;
      width: 50%;

      &--right {
        background: $light-gray-hover;
      }
    }

    .menu-overlay__items {
      padding: rem-calc(30) 0;

      &--level-3 {
        background: $light-gray-hover;

        .menu-overlay__item a {
          border-bottom-color: $divider-dark;
        }
      }
    }

    .menu-overlay__item {
      padding: 0 rem-calc(30);

      a {
        line-height: rem-calc(40);
        padding: 0 rem-calc(10);
      }
    }

    .menu-overlay__content {
      color: $primary;
      padding: rem-calc(20);

      .image {
        float: right;
        margin: 0 0 rem-calc(20) rem-calc(20);
        max-width: 25%;

        img {
          @include img-fluid();
        }

        &--only-desktop {
          display: none;
        }
      }

      .headline {
        margin-bottom: rem-calc(15);
      }

      .button {
        margin-top: rem-calc(30);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &--mobile {
      background-position: rem-calc(20) rem-calc(12);
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
  }

  @include media-breakpoint-up(lg) {
    &__item--only-mobile {
      display: none;
    }

    &--desktop {
      top: rem-calc(120);
      padding-left: rem-calc(40);
      padding-right: rem-calc(40);

      .menu-overlay__content {
        padding: rem-calc(30);

        .image {
          margin-left: rem-calc(40);
          max-width: 40%;

          &--only-desktop {
            display: block;
          }

          &--only-tablet {
            display: none;
          }
        }

        .headline {
          margin-bottom: rem-calc(20);
        }

        .button {
          margin-top: rem-calc(40);
        }
      }
    }
  }
}

.language-switcher {
  &__change-language {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    padding: rem-calc(11);
  }

  &__menu {
    li {
      a {
        color: $black;
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(17);
        min-width: rem-calc(120);
        padding: rem-calc(15);
        position: relative;

        .flag {
          float: left;
          margin-right: rem-calc(10);
        }

        @include hover {
          background: $light-gray;
        }
      }

      &:not(:last-child) a {
        padding-bottom: rem-calc(16);

        &:before,
        &:after {
          background: $white;
          bottom: 0;
          content: '';
          display: block;
          height: rem-calc(1);
          left: 0;
          position: absolute;
          right: 0;
        }

        &:after {
          background: $light-gray;
          left: rem-calc(15);
          right: rem-calc(15);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    right: rem-calc(20);
    top: rem-calc(-40);

    &__change-language {
      background: url('#{$image-base-uri}dropdown-arrow-down.svg') no-repeat right;
      float: right;
      color: $dark-gray;
      padding-right: rem-calc(20);

      span {
        display: none;
      }

      &:before {
        @include rem-size(16);
        background: url('#{$image-base-uri}icon-world.svg') no-repeat;
        content: '';
        display: block;
        margin: rem-calc(1) 0;
      }
    }

    &__menu {
      @include animate(opacity);
      background: #fff;
      box-shadow: 0 rem-calc(1) rem-calc(15) 0 rgba(39, 39, 39, 0.27);
      position: absolute;
      right: 0;
      top: rem-calc(40);
      z-index: 1;

      &--hidden {
        opacity: 0;
        pointer-events: none;
      }

      &--visible {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    right: rem-calc(40);
  }
}
