/**
 * Sector slider
 * -------------------------
 */

.ce-sector-slider {
  &__nav {
    background: $light-gray;
  }

  &__nav-item {
    background: $light-gray;
    color: $primary;
    display: block;
    font-size: rem-calc(17);
    font-weight: bold;
    line-height: rem-calc(26);
    min-width: rem-calc(160);
    padding: rem-calc(7) rem-calc(20);
    text-align: center;

    @at-root .slick-current &,
    &:hover {
      background: $primary;
      color: $white !important;
    }

    @media (pointer: none) {
      &:hover {
        background: $light-gray;
        color: $primary !important;
      }
    }
  }

  &__slide {
    display: table;
    min-height: rem-calc(400);
    position: relative;
    width: 100%;
  }

  &__background {
    @include wallpaper-element();
    background-color: $light-gray;
  }

  &__content {
    @include font-color-variants(dark);
    display: table-cell;
    padding: rem-calc(80) rem-calc(20);
    position: relative;
    text-align: center;
    vertical-align: middle;
  }

  &__icon {
    display: block;
    margin: 0 auto rem-calc(30);
    max-height: rem-calc(80);
  }

  .rte {
    margin: rem-calc(10) auto 0;
    max-width: rem-calc(600);
  }

  .link {
    margin-top: rem-calc(20);
  }

  @include media-breakpoint-up(md) {
    &__slide {
      min-height: rem-calc(450);
    }

    &__background--mobile {
      display: none;
    }

    .link {
      margin-top: rem-calc(30);
    }
  }

  @include media-breakpoint-up(lg) {
    &__nav-item {
      padding-bottom: rem-calc(12);
      padding-top: rem-calc(12);
    }

    &__slide {
      min-height: rem-calc(550);
    }

    &__content {
      padding-bottom: rem-calc(100);
      padding-top: rem-calc(100);
    }

    .link {
      margin-top: rem-calc(40);
    }
  }
}
