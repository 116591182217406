/**
 * Record List
 * -------------------------
 */

.ce-record-list {
  &__content {
    padding-bottom: rem-calc(17);
    position: relative;
    text-align: center;

    .headline {
      margin-bottom: rem-calc(5);
    }

    .rte {
      margin: 0 auto;
      max-width: rem-calc(640);
    }
  }

  &__link {
    padding-top: rem-calc(20);
  }

  @include media-breakpoint-up(sm) {
    &__content {
      padding-bottom: rem-calc(40);
    }

    &__link {
      border-top: rem-calc(2) solid $divider;
      margin-top: rem-calc(20);
      text-align: right;
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      @include make-row();
    }

    &__content {
      @include make-col-ready();
      @include make-col(1, 1);
      padding-bottom: rem-calc(80);
    }

    &__list {
      @include make-col-ready();
      @include make-col(3, 4);
    }

    &__link {
      @include make-col-ready();
      @include make-col(1, 4);
      align-self: stretch;
      border: none;
      border-left: rem-calc(2) solid $divider;
      margin: 0 $grid-gutter-width/-2 0 $grid-gutter-width/2;
      padding: 0;

      a {
        bottom: rem-calc(20);
        position: absolute;
        right: $grid-gutter-width;
      }
    }
  }
}
