/**
 * Link-List
 * -------------------------
 */

.ce-link-list-teaser {
  flex: 0 0 auto;

  &__list {
    @extend .unstyled-list;
    @extend %copy;

    li:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  &__link {
    @include animate(background);
    background: $light-gray url('#{$image-base-uri}icon-arrow-right-dark.svg') no-repeat right center;
    display: block;
    font-weight: bold;
    padding: rem-calc(7) rem-calc(45) rem-calc(7) rem-calc(15);
    position: relative;

    @each $type in download, external {
      &--#{$type} {
        padding-left: rem-calc(45);

        &:before {
          @include rem-size(30, 26);
          background: url('#{$image-base-uri}icon-#{$type}-dark.svg') no-repeat center;
          content: '';
          display: inline-block;
          left: rem-calc(15);
          margin-top: rem-calc(-13);
          position: absolute;
          top: 50%;
        }

        &:hover,
        &:focus {
          &:before {
            background-image: url('#{$image-base-uri}icon-#{$type}-dark-hover.svg');
          }
        }
      }
    }

    &:hover,
    &:focus {
      background-color: $light-gray-hover;
      background-position: right -10px center;
    }
  }
}
