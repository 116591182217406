/**
 * Form
 * -------------------------
 */

.ce-form {
  @include media-breakpoint-up(md) {
    @include make-row();

    &__wrapper {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      @include make-col(8);
      @include make-col-offset(2);

      &--small {
        @include make-col(6);
        @include make-col-offset(3);
      }
    }
  }
}
