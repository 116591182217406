/**
 * Footer
 * -------------------------
 */

.footer {
  background: $body-bg;

  &__back-to-top {
    @include animate(transform);
    bottom: rem-calc(20);
    position: fixed;
    right: calc(50% - #{rem-calc(20)});
    transform: translateY(rem-calc(80));
    z-index: 1;

    a {
      @include animate(box-shadow);
      @include rem-size(40);
      background: $body-bg url('#{$image-base-uri}icon-back-to-top.svg');
      box-shadow: $box-shadow-light-hover;
      border-radius: 50%;
      display: block;
      margin: 0 auto;
    }

    @at-root .scrolled & {
      transform: translateY(0);
    }
  }

  &__sections {
    padding: rem-calc(30) 0 rem-calc(85);
  }

  &__section-title {
    @include font-family($font-family-headlines);
    font-size: rem-calc(17);
    letter-spacing: rem-calc(1);
    line-height: rem-calc(26);
    margin: 0 0 rem-calc(10);
  }

  &__contact-menu {
    .link {
      margin-bottom: rem-calc(20);
    }
  }

  &__certification {
    &:not(:last-child) {
      margin-bottom: rem-calc(15);
    }

    figure {
      float: left;

      img {
        display: block;
        height: auto;
        width: rem-calc(50);
      }
    }

    ul {
      @extend .unstyled-list;
      padding-left: rem-calc(70);

      li:not(:last-child) {
        margin-bottom: rem-calc(5);
      }

      a {
        @include animate(opacity);
        color: $medium-gray;
        font-size: rem-calc(14);
        line-height: rem-calc(17);
        opacity: 0.5;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 575px) {
    &__sections {
      border-top: rem-calc(1) solid $divider;
    }

    &__section:not(:last-child) {
      margin-bottom: rem-calc(30);
    }
  }

  @include media-breakpoint-down(sm) {
    &__inner-section:not(:last-child) {
      margin-bottom: rem-calc(30);
    }
  }

  @media (min-width: 576px) {
    &__sections {
      @include make-row();
    }

    &__section {
      @include make-col-ready();
      @include make-col(1, 2);
    }
  }

  @include media-breakpoint-up(md) {
    &__sections {
      padding-bottom: rem-calc(100);
      padding-top: rem-calc(40);
    }

    &__section {
      @include make-row();
    }

    &__inner-section {
      @include make-col-ready();
      @include make-col(1, 2);
    }

    &__section-title {
      margin-bottom: rem-calc(20);
    }
  }

  @include media-breakpoint-up(lg) {
    &__back-to-top {
      bottom: rem-calc(40);
      right: rem-calc(40);
      transform: translateY(rem-calc(100));
    }

    &__sections {
      padding-bottom: rem-calc(140);
      padding-top: rem-calc(60);
    }
  }

  @media (min-width: 1870px) {
    &__back-to-top a {
      box-shadow: none;
    }
  }
}
