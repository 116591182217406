/**
 * Accordion
 * -------------------------
 */

.accordion {
  border-top: rem-calc(2) solid $divider;

  &__pane {
    border-bottom: rem-calc(2) solid $divider;
  }

  &__pane-title {
    position: relative;

    button {
      @include headline(sm);
      background: none;
      border: none;
      color: $primary;
      display: block;
      padding: rem-calc(20) rem-calc(60) rem-calc(20) 0;
      position: relative;
      text-align: left;
      width: 100%;
    }

    &:before {
      @include animate(transform);
      @include rem-size(40);
      background: url('#{$image-base-uri}icon-arrow-big-right-dark.svg') no-repeat center;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }

    &--open {
      &:before {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &__pane-body {
    display: none;
    padding-bottom: rem-calc(30);
  }

  &--dense {
    border-top: none;

    .accordion {
      &__pane {
        border-bottom: rem-calc(1) solid $primary;

        &-title {
          button {
            @include copy-text();
            padding: rem-calc(15) rem-calc(28) rem-calc(15) 0;
          }

          &:before {
            @include rem-size(14);
            background-image: url('#{$image-base-uri}icon-plus.svg');
            transform: translateY(-50%);
          }

          &--open {
            &:before {
              background-image: url('#{$image-base-uri}icon-minus.svg');
              transform: translateY(-50%);
            }
          }
        }

        &-body {
          padding-bottom: rem-calc(20);
        }
      }
    }
  }
}
