/**
 * Stage for magazine
 * -------------------------
 */

.ce-magazine-stage {
  @include clearfix();

  &__wrapper {
    background: $primary;
    position: relative;
  }

  &__background {
    @include wallpaper-element();
    opacity: 0.8;
  }

  &__content {
    @include font-color-variants(light);
    padding: rem-calc(70) rem-calc(20);
    position: relative;
    text-align: center;
  }

  .share {
    margin-top: rem-calc(10);
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding: rem-calc(130) rem-calc(60);
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding: rem-calc(190) rem-calc(120);
    }
  }
}
