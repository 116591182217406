/**
 * Text
 * -------------------------
 */

.ce-text {
  .headline {
    margin-bottom: rem-calc(20);
  }

  &__aside {
    .rte {
      border-left: rem-calc(2) solid $divider;
      margin: 0 0 rem-calc(40);
      padding: 0 0 0 rem-calc(18);
    }
  }

  &--center {
    .headline,
    .rte {
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {
    &__sub-elements {
      margin-top: rem-calc(40);

      &--above {
        display: none;
      }
    }

    &--with-aside {
      .ce-text__row {
        flex-wrap: wrap;
        display: flex;
      }

      .ce-text__content {
        flex: 0 0 100%;
        order: 1;
        max-width: 100%;
      }

      .ce-text__aside {
        flex: 0 0 100%;
        margin-bottom: rem-calc(20);

        &--below-content {
          order: 1;

          .rte {
            margin: rem-calc(20) 0 0;
          }

          > .button {
            margin-top: rem-calc(20);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__wrapper {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }

    &__content {
      @include clearfix();
      margin: 0 auto;
      max-width: rem-calc(780);
    }

    &__sub-elements {
      margin-bottom: $grid-gutter-width;
      width: calc(50% - #{$grid-gutter-width / 2});

      &--left {
        float: left;
        margin-right: $grid-gutter-width;
      }

      &--right {
        float: right;
        margin-left: $grid-gutter-width;
      }

      &--below {
        display: none;
      }
    }

    &--with-aside {
      .ce-text__row {
        @include make-row();
        align-items: flex-start;
      }

      .ce-text__content {
        @include make-col-ready();
        @include make-col(2, 3);
      }

      .ce-text__aside {
        @include make-col-ready();
        @include make-col(1, 3);
        position: sticky;
        top: rem-calc(40);
      }
    }
  }

  @include media-breakpoint-only(md) {
    &--with-aside {
      .ce-text__wrapper {
        @include make-col(12);
        @include make-col-offset(0);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--with-aside {
      .ce-text__content {
        @include make-col(7, 10);
      }

      .ce-text__aside {
        @include make-col(3, 10);
      }
    }
  }
}
