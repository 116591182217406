/**
 * Styling for RTE-editor
 * --------------------
 */

.rte {
  h2 {
    @extend %headline-md;
    margin: rem-calc(30) 0 rem-calc(17);

    @include media-breakpoint-up(sm) {
      margin-top: rem-calc(40);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    @extend %headline-sm;
    margin: rem-calc(30) 0 rem-calc(17);
  }

  p {
    @extend %copy;
    margin: 0 0 rem-calc(17);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    @extend %copy;
    margin: 0 0 rem-calc(17);
    padding: 0 0 0 rem-calc(40);

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin: 0 0 rem-calc(10) 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        @include rem-size(6);
        background: $primary;
        border-radius: 50%;
        content: '';
        display: block;
        left: rem-calc(-25);
        position: absolute;
        top: rem-calc(8);
      }
    }
  }

  table {
    @extend %copy;
    margin: 0 0 rem-calc(17);
    width: 100% !important;

    &:last-child {
      margin-bottom: 0;
    }

    td, th {
      border: rem-calc(1) solid $divider;
      padding: rem-calc(4) rem-calc(5);
      vertical-align: top;
    }

    th {
      background: $light-gray;
    }

    &.no-border {
      td, th {
        border: none;
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  a {
    font-weight: bold;
  }

  .highlight {
    background: $light-gray;
    padding: rem-calc(17) rem-calc(20);

    &:not(:last-child) {
      margin-bottom: rem-calc(17);
    }
  }

  blockquote {
    border-left: rem-calc(2) solid $primary;
    margin: 0 0 rem-calc(17);
    padding-left: rem-calc(18);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--small {
    p {
      @include tiny-text();
    }
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
