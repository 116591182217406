/**
 * Helpers for typography
 * --------------------
 */

%tiny {
  @include tiny-text();
}

%copy {
  @include copy-text();
}

%headline-sm {
  @include headline(sm);
}

%headline-md {
  @include headline(md);
}

%headline-lg {
  @include headline(lg);
}

%headline-xl {
  @include headline(xl);
}

@each $alignment in left center right justify {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

