/**
 * Divider
 * -------------------------
 */

.ce-divider {
  @include make-row();

  &__wrapper {
    @include make-col-ready();
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      @include make-col(10, 12);
      @include make-col-offset(1, 12);
    }
  }

  @at-root [hidden] + & {
    display: none;
  }
}
