/**
 * Teaser slider
 * -------------------------
 */

.ce-teaser-slider {
  &__content {
    margin: 0 auto rem-calc(30);
    max-width: rem-calc(785);
    text-align: center;

    .headline:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  &__slide {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    text-align: center;

    img {
      object-fit: contain;
      width: auto;
      max-width: 100%;
      height: rem-calc(120);
      margin: 0 auto;
    }
  }

  &--carousel {
    margin-left: rem-calc(-15);
    margin-right: rem-calc(-15);

    @include media-breakpoint-up(md) {
      margin-left: rem-calc(-20);
      margin-right: rem-calc(-20);
    }

    @include media-breakpoint-up(lg) {
      margin-left: rem-calc(-40);
      margin-right: rem-calc(-40);
    }
  }

  &--slider {
    .ce-teaser-slider {
      &__slides {
        margin-left: rem-calc(60);
        margin-right: rem-calc(60);
      }
    }

    .slick-arrow {
      &.slick-next {
        top: calc(50% - #{rem-calc(20)});
        right: rem-calc(-50);
      }

      &.slick-prev {
        top: calc(50% - #{rem-calc(20)});
        left: rem-calc(-50);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin-bottom: rem-calc(40);
    }
  }
}
