/**
 * Global
 * -------------------------
 */

ul.unstyled,
nav ul {
  @extend .unstyled-list;
}

blockquote,
figure {
  margin: 0;
}

a {
  cursor: pointer;
}

[data-zoom-src] {
  cursor: zoom-in;
}

hr {
  margin: rem-calc(20) 0;
  border: none;
  border-bottom: rem-calc(2) solid $divider;
}

.headline {
  margin: 0;

  &--xl {
    @extend %headline-xl;
  }

  &--lg {
    @extend %headline-lg;
  }

  &--md {
    @extend %headline-md;
  }

  &--sm {
    @extend %headline-sm;
  }
}

.content {
  padding: 0 rem-calc(15);

  @include media-breakpoint-up(md) {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc(20);
}

.link {
  @include copy-text();
  background: none;
  border: none;
  color: $link-color;
  display: inline-block;
  font-weight: bold;
  padding: 0;

  &--arrow,
  &--download,
  &--external {
    background: no-repeat 0 0;
    font-weight: bold;
    padding-left: rem-calc(35);
  }

  &--arrow {
    @include animate(background-position);
    background-image: url('#{$image-base-uri}icon-arrow-right-dark.svg');

    @include hover-focus() {
      background-position: rem-calc(10) 0;
    }
  }

  &--external {
    background-image: url('#{$image-base-uri}icon-external-dark.svg');

    @include hover-focus() {
      background-image: url('#{$image-base-uri}icon-external-dark-hover.svg');
    }
  }

  &--download {
    background-image: url('#{$image-base-uri}icon-download-dark.svg');

    @include hover-focus() {
      background-image: url('#{$image-base-uri}icon-download-dark-hover.svg');
    }
  }

  &--button {
    @extend .button;
  }

  &--email,
  &--phone,
  &--fax,
  &--info {
    background: no-repeat left center;
    background-size: rem-calc(16) rem-calc(16);
    padding-left: rem-calc(26);
  }

  &--email {
    background-image: url('#{$image-base-uri}icon-email-alt.svg');
    font-weight: bold;
  }

  &--phone {
    background-image: url('#{$image-base-uri}icon-phone.svg');
    color: $body-color;

    &:hover,
    &:focus {
      color: $body-color;
    }
  }

  &--fax {
    background-image: url('#{$image-base-uri}icon-fax.svg');
  }

  &--info {
    background-image: url('#{$image-base-uri}icon-info.svg');
    font-weight: bold;
  }

  &--light-font {
    &.link--arrow,
    &.link--download,
    &.link--external {
      color: $white;
    }

    &.link--arrow {
      background-image: url('#{$image-base-uri}icon-arrow-right-light.svg');
    }

    &.link--external {
      background-image: url('#{$image-base-uri}icon-external-light.svg');

      @include hover-focus() {
        background-image: url('#{$image-base-uri}icon-external-light-hover.svg');
      }
    }

    &.link--download {
      background-image: url('#{$image-base-uri}icon-download-light.svg');

      @include hover-focus() {
        background-image: url('#{$image-base-uri}icon-download-light-hover.svg');
      }
    }

    &.link--button {
      @extend .button--light-font;
    }
  }

  &--black-font {
    &.link--arrow,
    &.link--download,
    &.link--external {
      color: $black;
    }

    &.link--arrow {
      background-image: url('#{$image-base-uri}icon-arrow-right-black.svg');
    }

    &.link--external {
      background-image: url('#{$image-base-uri}icon-external-black.svg');

      @include hover-focus() {
        background-image: url('#{$image-base-uri}icon-external-black-hover.svg');
      }
    }

    &.link--download {
      background-image: url('#{$image-base-uri}icon-download-black.svg');

      @include hover-focus() {
        background-image: url('#{$image-base-uri}icon-download-black-hover.svg');
      }
    }

    &.link--button {
      @extend .button--black-font;
    }
  }

  &--tiny {
    @include tiny-text();

    &.link--email,
    &.link--phone,
    &.link--fax,
    &.link--info {
      background-size: rem-calc(12) rem-calc(12);
      padding-left: rem-calc(22);
    }
  }

  &--margin-top {
    margin-top: rem-calc(40);

    @include media-breakpoint-up(md) {
      margin-top: rem-calc(60);
    }

    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(80);
    }
  }
}

.close {
  @include rem-size(40);
  background: url('#{$image-base-uri}icon-close.svg') no-repeat center;
  border: none;
  display: block;

  &:hover,
  &:focus {
    background-image: url('#{$image-base-uri}icon-close-hover.svg');
  }
}

.definition-list {
  border-left: rem-calc(2) solid $divider;
  margin: 0 0 rem-calc(40);
  padding: 0 0 0 rem-calc(18);

  dt {
    @include tiny-text();
    color: transparentize($medium-gray, 0.6);
    font-weight: normal;
    margin: 0 0 rem-calc(5);
  }

  dd {
    @include copy-text();
    margin: 0 0 rem-calc(20);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.topline {
  font-size: rem-calc(14);
  line-height: rem-calc(17);
}

.figure {
  margin: 0;

  img {
    @include img-fluid();
  }

  figcaption {
    @extend %tiny;
    margin: rem-calc(10) 0 0;
    text-align: left;
  }
}

.video-wrapper {
  overflow: hidden;
  position: relative;

  &::before {
    display: block;
    content: '';
    padding-top: percentage(9/16);
  }

  iframe,
  video {
    @include stretch-to-parent();
    height: 100%;
    width: 100%;
  }

  [data-type="placeholder"] {
    @include stretch-to-parent();
    max-width: 100% !important;
    height: 100% !important;
  }

  &--4by3::before {
    padding-top: percentage(3/4);
  }
}

.spam-protection {
  &--at:before {
    content: '@';
  }

  &--dot:before {
    content: '.';
  }

  &__inner {
    display: inline-block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

.flag {
  @include rem-size(24, 17);
  background: no-repeat center;
  background-size: contain;
  display: inline-block;

  @each $flag in cn, cz, de, en-us-gb, es, fr, jpg, pl, ru, tr {
    &--#{$flag} {
      background-image: url('#{$image-base-uri}flag-#{$flag}.svg');
    }
  }
}

.record-list {
  @extend .unstyled-list;

  &__record {
    &-overline {
      @extend %tiny;
      margin-bottom: rem-calc(5);
    }

    &-title {
      @extend %copy;
      color: $body-color;
    }

    &-subline {
      @extend %tiny;
      color: $text-muted;
      margin-top: rem-calc(5);
    }

    a {
      @include animate(background padding);
      display: block;
      margin-top: rem-calc(-2);
      padding: rem-calc(22) rem-calc(20) rem-calc(22) 0;
      position: relative;

      &:before,
      &:after {
        @include rem-size(50, 2);
        background: $divider;
        content: '';
        display: block;
        left: 0;
        position: absolute;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      &:hover,
      &:focus {
        background-color: $light-gray-hover;
        padding-left: rem-calc(20);
        padding-right: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &--expandable {
      @include make-row();
      flex-wrap: nowrap;

      .record-list__record {
        flex: 0 1 auto;
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
        width: 100%;

        a {
          background-image: url('#{$image-base-uri}icon-arrow-right-dark.svg');
          background-position: left bottom rem-calc(20);
          background-repeat: no-repeat;
          height: 100%;
          margin-top: 0;
          padding-bottom: rem-calc(64);
          padding-top: rem-calc(20);

          &:before,
          &:after {
            display: none;
          }

          &:hover,
          &:focus {
            background-position: left rem-calc(20) bottom rem-calc(20);
          }
        }
      }
    }
  }
}

.address {
  max-width: rem-calc(740);

  &:not(:last-child) {
    margin-bottom: rem-calc(40);
  }

  p,
  address {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  @include media-breakpoint-down(sm) {
    &__address {
      margin-bottom: rem-calc(20);
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__address,
    &__contact {
      @include make-col-ready();
      @include make-col(1, 2);
    }
  }
}

.success-messages {
  color: $white;
  background: $success;
  margin: 0 0 rem-calc(30);
  padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(40);
}

.error-messages {
  color: $white;
  background: $error;
  margin: 0 0 rem-calc(30);
  padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(40);
}

.info-messages {
  color: $white;
  background: $primary;
  margin: 0 0 rem-calc(30);
  padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(40);
}

.typo3-messages {
  @extend .unstyled-list;

  .alert-danger {
    @extend .error-messages;
  }

  .alert-success {
    @extend .success-messages;
  }

  .alert-message {
    margin: 0;
  }
}

.action-bar {
  background: $white;
  bottom: 0;
  left: 0;
  padding: rem-calc(10) rem-calc(15);
  position: fixed;
  right: 0;
  z-index: 10;
}
