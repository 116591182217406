/**
 * Product detail
 * -------------------------
 */

.ce-product-detail {
  &__description {
    .topline,
    .headline {
      margin-bottom: rem-calc(10);
    }

    .rte:not(:last-child) {
      margin-bottom: rem-calc(30);
    }
  }

  &__price {
    display: inline-flex;
    margin-bottom: rem-calc(10);

    .price {
      font-size: rem-calc(25);
      line-height: rem-calc(35);
      font-weight: bold;

      &-vat {
        font-size: rem-calc(25);
        line-height: rem-calc(35);
        margin-left: rem-calc(5);
      }
    }
  }

  &__sku {
    margin-top: rem-calc(10);
  }

  &__content {
    width: 100%;
    padding-top: rem-calc(60);

    .rte {
      margin: 0 auto;
      max-width: rem-calc(780);
    }
  }

  &--with-images {
    .ce-product-detail__images {
      margin-bottom: rem-calc(30);

      picture {
        display: block !important;
      }

      img {
        max-width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }

    .ce-product-detail__images-slider {
      margin-bottom: rem-calc(10);
    }

    .ce-product-detail__images-thumbs {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: rem-calc(10);

      > a {
        @include animate(opacity);
        display: block;
        opacity: 0.33;

        &:hover,
        &:focus,
        &.current {
          opacity: 1;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .ce-product-detail__images-thumbs {
        grid-template-columns: repeat(6, auto);
      }
    }

    @include media-breakpoint-up(md) {
      @include make-row();

      .ce-product-detail__images {
        @include make-col-ready();
        @include make-col(1, 3);
        margin-bottom: rem-calc(0);
      }

      .ce-product-detail__description {
        @include make-col-ready();
        @include make-col(2, 3);
      }

      .ce-product-detail__images-thumbs {
        display: grid;
        grid-template-columns: repeat(4, auto);
      }
    }

    @include media-breakpoint-up(lg) {
      .ce-product-detail__images-thumbs {
        grid-template-columns: repeat(6, auto);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding-top: rem-calc(100);
    }
  }
}

.add-to-basket {
  max-width: rem-calc(310);

  &__variant-selector {
    margin-bottom: rem-calc(20);
  }

  &__button {
    display: flex;
    gap: rem-calc(20);
  }
}
