/**
 * Cookie-Consent
 * --------------------
 */

@import "../../../../node_modules/klaro/src/scss/klaro";

.klaro {
  --light1: #{$body-color};
  --dark1: #{$light-gray};
  --dark2: #{$medium-gray};
  --green1: #{$primary};
  --green2: #{$primary};
  --blue1: #{$primary};
  --border-radius: 0;

  .cookie-notice:not(.cookie-modal-notice) {
    z-index: 1000;
  }

  .cookie-modal .cm-list-label .slider,
  .context-notice .cm-list-label .slider,
  .cookie-notice .cm-list-label .slider {
    &:before {
      background-color: var(--dark2);
    }
  }

  .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
  .context-notice .cm-list-input:checked + .cm-list-label .slider,
  .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
    &:before {
      background-color: var(--dark1);
    }
  }

  &.cm-as-context-notice {
    padding: 0;
  }
}
