/**
 * Page-Intro
 * -------------------------
 */

.ce-page-intro {
  @include clearfix();

  &__wrapper {
    height: rem-calc(320);
    position: relative;
  }

  &__background-slide {
    height: rem-calc(320);
  }

  &__background {
    @include wallpaper-element();
  }

  &__content {
    background: $primary;
    bottom: rem-calc(-50);
    color: $white;
    left: rem-calc(-15);
    max-width: calc(100% - #{rem-calc(40)});
    padding: rem-calc(15) rem-calc(20);
    position: absolute;
  }

  .share {
    margin-top: rem-calc(10);
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    &__content {
      padding: rem-calc(25) rem-calc(42);
    }
  }

  @include media-breakpoint-up(md) {
    &__wrapper,
    &__background-slide {
      height: rem-calc(600);
    }

    &__content {
      bottom: rem-calc(-70);
      left: rem-calc(-20);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      left: rem-calc(-40);
      max-width: rem-calc(880);
    }
  }
}
