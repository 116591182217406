/**
 * Stage
 * -------------------------
 */

.ce-stage {
  &__slide {
    display: table;
    min-height: rem-calc(400);
    position: relative;
    width: 100%;
  }

  &__background {
    @include wallpaper-element();
    background-color: $light-gray;

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    @include font-color-variants(dark);
    @include background-color-variants(null, 0.5);
    display: table-cell;
    padding: rem-calc(80) rem-calc(20);
    position: relative;
    vertical-align: middle;
  }

  .rte {
    margin-top: rem-calc(10);
  }

  .link {
    margin-top: rem-calc(20);
  }

  @include media-breakpoint-down(sm) {
    &__background--desktop {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__slide {
      min-height: rem-calc(450);
    }

    &__background--mobile {
      display: none;
    }

    &__content {
      padding-left: rem-calc(60);
      padding-right: rem-calc(60);
    }

    .headline,
    .rte {
      width: 70%;
    }

    .link {
      margin-top: rem-calc(30);
    }
  }

  @include media-breakpoint-up(lg) {
    &__slide {
      min-height: rem-calc(550);
    }

    &__content {
      padding: rem-calc(100) rem-calc(80);
    }

    .rte {
      max-width: rem-calc(640);
      width: 40%;
    }

    .link {
      margin-top: rem-calc(40);
    }
  }
}
