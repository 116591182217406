/**
 * Text with image
 * -------------------------
 */

.ce-text-image {
  .headline {
    margin-bottom: rem-calc(20);
  }

  &__image {
    text-align: center;
  }

  @include media-breakpoint-down(xs) {
    &__image {
      margin-bottom: rem-calc(10);

      @at-root .ce-text-image--small & img {
        max-width: rem-calc(100);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__columns {
      @include make-row();
      align-items: center;
    }

    &__image {
      @include make-col-ready();
      @include make-col(4);

      @at-root .ce-text-image--right & {
        order: 1;
      }

      @at-root .ce-text-image--small & {
        @include make-col(2);
      }

      @at-root .ce-text-image--large & {
        @include make-col(6);
      }
    }

    &__content {
      @include make-col-ready();
      @include make-col(8);

      @at-root .ce-text-image--small & {
        @include make-col(10);
      }

      @at-root .ce-text-image--large & {
        @include make-col(6);
      }
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__wrapper {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      @include make-col(8);
      @include make-col-offset(2);

      @at-root .ce-text-image--small & {
        @include make-col(6);
        @include make-col-offset(3);
      }
    }
  }
}
