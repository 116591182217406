/**
 * Grid
 * -------------------------
 */

.ce-teaser-list {
  &__content {
    margin: 0 auto rem-calc(30);
    max-width: rem-calc(785);
    text-align: center;

    .headline {
      margin-bottom: rem-calc(10);
    }
  }

  &__teasers {
    margin-bottom: rem-calc(-30);

    .headline {
      margin-bottom: rem-calc(30);
      margin-top: rem-calc(10);
    }
  }

  &__teaser {
    display: block;
    margin-bottom: rem-calc(30);

    img {
      @include animate(opacity);
      @include img-fluid();
      display: block;
      margin-bottom: rem-calc(15);
    }

    strong {
      @extend %headline-md;
      display: block;
      padding-right: rem-calc(40);
      position: relative;

      svg {
        @include animate(transform);
        fill: currentColor;
        position: absolute;
        right: 0;
        top: rem-calc(-5);
      }
    }

    &:hover,
    &:focus {
      img {
        opacity: 0.6;
      }

      strong svg {
        transform: translateX(rem-calc(10));
      }
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__wrapper {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }

    &__content {
      margin-bottom: rem-calc(40);
    }

    &__teasers {
      @include make-row(rem-calc(40));
      margin-bottom: rem-calc(-40);

      .headline {
        @include make-col-ready(rem-calc(40));
        @include make-col(2, 2);
        margin-bottom: rem-calc(40);
        margin-top: rem-calc(20);
      }
    }

    &__teaser {
      @include make-col-ready(rem-calc(40));
      @include make-col(1, 2);
      margin-bottom: rem-calc(40);

      strong svg {
        top: rem-calc(-2);
      }
    }

    &--with-image {
      .ce-teaser-list__teasers {
        margin-bottom: rem-calc(60);
      }

      .ce-teaser-list__teaser {
        img {
          margin-bottom: rem-calc(20);
        }

        &:nth-child(odd) {
          top: rem-calc(100);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__teasers {
      @include make-row(rem-calc(80));

      .headline {
        @include make-col-ready(rem-calc(80));
        margin-bottom: rem-calc(60);
      }
    }

    &__teaser {
      @include make-col-ready(rem-calc(80));

      strong svg {
        top: rem-calc(4);
      }
    }

    &--with-image {
      .ce-teaser-list__content {
        margin-bottom: rem-calc(60);
      }

      .ce-teaser-list__teaser {
        margin-bottom: rem-calc(60);
      }

      .ce-teaser-list__teasers {
        margin-bottom: rem-calc(40);
      }
    }
  }
}
